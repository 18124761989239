@import "src/themes/daikiri/styles/index.scss";
.carousel {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  touch-action: pan-y;

  img {
    -webkit-user-drag: none;
  }
}

.slide {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
  cursor: default;
  user-select: none;

  &.fade-in {
    opacity: 1;
  }

  &.fade-out {
    opacity: 0;
  }
}

.dots {
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 16px;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: getvar($colors, "primary", "200");
  border-radius: 50%;
  cursor: pointer;

  &.active {
    background-color: getvar($colors, "primary", "700");
  }
}
