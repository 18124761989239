// Small devices
@mixin xs {
  @media (min-width: #{getvar($breakpoints, 'xs')}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{getvar($breakpoints, 'sm')}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{getvar($breakpoints, 'md')}) {
    @content;
  }
}

@mixin md-macropay {
  @media (min-width: #{getvar($breakpoints, 'md-macropay')}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{getvar($breakpoints, 'lg')}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{getvar($breakpoints, 'xl')}) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: #{getvar($breakpoints, 'xxl')}) {
    @content;
  }
}
