@import "src/themes/daikiri/styles/index.scss";
.auth__header {
  display: flex;
  flex: 0.5;
  width: 100%;
  padding-left: space(1.5);
  padding-top: space(1);
  padding-bottom: space(1);
  background-color: white;

  @include lg {
    padding-left: space(3);
    background-color: transparent;
  }
  .header__logo_auth {
    max-width: 100%;
    width: 483px;
  }
}
