.u-spacing-0 {
  margin-bottom: 0 !important;

  &--top {
    margin-top: 0 !important;
  }
}

.u-spacing-1 {
  margin-bottom: space(0.25);

  &--top {
    margin-top: space(0.25);
  }
}

.u-spacing-2 {
  margin-bottom: space(0.5);

  &--top {
    margin-top: space(0.5);
  }
}

.u-spacing-3 {
  margin-bottom: space(0.75);

  &--top {
    margin-top: space(0.75);
  }
}

.u-spacing-4 {
  margin-bottom: space();

  &--top {
    margin-top: space();
  }
}

.u-spacing-5 {
  margin-bottom: space(1.5);

  &--top {
    margin-top: space(1.5);
  }
}

.u-spacing-5-top {
  margin-top: space(1.5);
}

.u-spacing-6 {
  margin-bottom: space(2);

  &--top {
    margin-top: space(2);
  }
}

.u-spacing-7 {
  margin-bottom: space(2.5) !important;

  &--top {
    margin-top: space(2.5);
  }
}

.u-spacing-8 {
  margin-bottom: space(3);

  &--top {
    margin-top: space(3);
  }

  @include md {
    margin-bottom: space(4);
  }
}

.u-spacing-9 {
  margin-bottom: space(3);

  &--top {
    margin-top: space(3);
  }

  @include md {
    margin-bottom: space(4.5);
  }
}

.u-spacing-10 {
  margin-bottom: space(4);

  &--top {
    margin-top: space(4);
  }

  @include md {
    margin-bottom: space(6);
  }
}

.u-spacing-10-top {
  margin-top: space(4);

  @include md {
    margin-top: space(6);
  }
}

.u-spacing-11 {
  margin-bottom: space(8);

  &--top {
    margin-top: space(8);
  }
}

.u-padding-0 {
  padding-bottom: 0 !important;

  &--top {
    padding-top: 0 !important;
  }
}

.u-padding-1 {
  padding-bottom: space(0.25);

  &--top {
    padding-top: space(0.25);
  }
}

.u-padding-2 {
  padding-bottom: space(0.5);

  &--top {
    padding-top: space(0.5);
  }
}

.u-padding-3 {
  padding-bottom: space(0.75);

  &--top {
    padding-top: space(0.75);
  }
}

.u-padding-4 {
  padding-bottom: space();

  &--top {
    padding-top: space();
  }
}

.u-padding-5 {
  padding-bottom: space(1.5);

  &--top {
    padding-top: space(1.5);
  }
}

.u-padding-5-top {
  padding-top: space(1.5);
}

.u-padding-6 {
  padding-bottom: space(2);

  &--top {
    padding-top: space(2);
  }
}

.u-padding-7 {
  padding-bottom: space(2.5);

  &--top {
    padding-top: space(2.5);
  }
}

.u-padding-8 {
  padding-bottom: space(3);

  &--top {
    padding-top: space(3);
  }

  @include md {
    padding-bottom: space(4);
  }
}

.u-padding-9 {
  padding-bottom: space(3);

  &--top {
    padding-top: space(3);
  }

  @include md {
    padding-bottom: space(4.5);
  }
}

.u-padding-10 {
  padding-bottom: space(4);

  &--top {
    padding-top: space(4);
  }

  @include md {
    padding-bottom: space(6);
  }
}

.u-padding-10-top {
  padding-top: space(4);

  @include md {
    padding-top: space(6);
  }
}

.u-padding-11 {
  padding-bottom: space(8);

  &--top {
    padding-top: space(8);
  }
}

.u-shadow-small {
  box-shadow: 0 5px 8px 0 rgba(getvar($colors, "neutral", "700"), 0.18);
}

.u-shadow-medium {
  box-shadow: 0 5px 8px 0 rgba(getvar($colors, "neutral", "700"), 0.24);
}

.u-borderRadius-small {
  border-radius: space(0.25);
}

.u-borderRadius-medium {
  border-radius: space(0.5);
}

.u-margin-horizontal {
  margin: 0 space(0.5);
}

.u-margin-vertical {
  margin: 0 space(0.5);
}

.u-none {
  display: none;
}

.u-block {
  display: block;
}

.u-inline {
  display: inline;
}

.u-nowrap {
  white-space: nowrap;
}

.u-nowrap-bold {
  white-space: nowrap;
  font-weight: 700;
}

.u-noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.u-box {
  background-color: getvar($colors, "neutral", "white");
  @extend .u-borderRadius-medium;
  padding: space(1.5) space(1.5);
  flex: 1 1 0px;
  box-sizing: border-box;

  &--xsmall {
    background-color: getvar($colors, "neutral", "white");
    @extend .u-borderRadius-medium;
    padding: space(1) space();

    @include sm {
      padding: space(1);
    }
  }

  &--small {
    background-color: getvar($colors, "neutral", "white");
    @extend .u-borderRadius-medium;
    padding: space(1.5) space();

    @include sm {
      padding: space(1.5);
    }
  }

  &--compact {
    @include sm {
      padding: space(2) space(3);
    }
  }

  &--center {
    text-align: center;
  }

  &--left {
    text-align: left;
  }

  &--background {
    background-color: getvar($colors, "neutral", "white");
    padding: space() space() 0 space();
    display: inline-block;
  }
  &--extra {
    @include md {
      padding-left: space(16);
      padding-right: space(16);
    }
  }
}

.u-full {
  width: 100%;
}

.u-grid {
  display: grid;

  &--col {
    grid-template-columns: repeat(1, 1fr);
    column-gap: space(2);

    @include md {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &--col-3 {
    grid-template-columns: repeat(1, 1fr);
    column-gap: space(2);

    @include md {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &--col-4 {
    grid-template-columns: repeat(1, 1fr);
    column-gap: space(2);

    @include md {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.u-flex {
  display: flex;
  gap: space(0.875);

  @include md {
    gap: space(0);
  }

  &--center {
    justify-content: center;
    align-items: center;
  }

  &--desktop {
    display: block;

    @include md {
      display: flex;
      column-gap: space(2);
    }
  }

  &--wrap {
    flex-wrap: wrap;
  }

  &--space-between {
    justify-content: space-between;
  }

  &--small-gap {
    column-gap: space(1);
  }

  &--space-evenly {
    justify-content: space-evenly;
  }
}

.u-flex-half {
  width: 100%;

  @include md {
    width: 46%;
  }
}

.u-flex-full {
  width: 100%;
}

.u-flex-column {
  flex-direction: column;
}

.u-flex-row {
  flex-direction: row;
}

.u-align-self {
  align-self: center;

  &--center {
    align-self: center;
  }

  &--start {
    align-self: start;
  }

  &--end {
    align-self: flex-end;
  }
}

.u-align {
  align-items: center;

  &--center {
    align-items: center;
  }

  &--start {
    align-items: flex-start;
  }

  &--end {
    align-items: flex-end;
  }

  &--baseline {
    align-items: baseline;
  }

  &--stretch {
    align-items: stretch;
  }
}

.u-justify {
  justify-content: center;

  &--center {
    justify-content: center;
  }

  &--start {
    justify-content: flex-start;
  }

  &--end {
    justify-content: flex-end;
  }

  &--space-between {
    justify-content: space-between;
  }

  &--space-evenly {
    justify-content: space-evenly;
  }

  &--space-around {
    justify-content: space-around;
  }
}

.u-justify-self {
  justify-self: center;

  &--center {
    justify-self: center;
  }

  &--start {
    justify-self: start;
  }

  &--end {
    justify-self: flex-end;
  }
}

.u-gap-0 {
  gap: 0px;

  &--col {
    column-gap: 0px;
  }

  &--row {
    row-gap: 0px;
  }
}

.u-gap-4 {
  gap: space();

  &--col {
    column-gap: space();
  }

  &--row {
    row-gap: space();
  }
}

.u-gap-5 {
  gap: space(1.5);

  &--col {
    column-gap: space(1.5);
  }

  &--row {
    row-gap: space(1.5);
  }
}

.u-gap-6 {
  gap: space(2);

  &--col {
    column-gap: space(2);
  }

  &--row {
    row-gap: space(2);
  }
}

.u-text-left {
  text-align: left;
}

.u-text-center {
  text-align: center;
}

.u-text-right {
  text-align: right;
}

.u-noMB {
  margin-bottom: 0;
}

.u-modal {
  max-width: 90vw;
  position: relative;
  outline: none;
  max-height: 100%;

  @include sm {
    max-width: 437px;
  }

  &--big {
    @include sm {
      max-width: 560px;
    }
  }
}

.u-light {
  font-weight: 400;

  &--important {
    font-weight: 400 !important;
  }
}

.u-bold {
  font-weight: bold;

  &--important {
    font-weight: bold !important;
  }
}

.u-semibold {
  font-weight: 600;

  &--important {
    font-weight: 600 !important;
  }
}

.u-weight-700 {
  font-weight: 700;

  &--important {
    font-weight: 700 !important;
  }
}

.u-modal__overlay {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(getvar($colors, "neutral", "900"), 0.75);
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  z-index: 2;
}

.u-modal__close {
  position: absolute;
  right: space(0.75);
  top: space(0.75);

  &:hover {
    cursor: pointer;
  }
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.u-br-desktop {
  display: block;
}

.u-br-paragraph {
  margin-top: 24px;
  display: block;
}

.u-active {
  outline: solid 2px getvar($colors, "primary", "500");
  border: 1px solid transparent !important;
}

.u-card {
  background-color: getvar($colors, "neutral", "50");
  border-radius: space(0.25);
  border: 1px solid getvar($colors, "neutral", "100");
  padding: space();
  text-align: left;
  position: relative;

  &--image {
    margin-top: space(4.5);
    padding-top: space(4);

    .u-imagePlaceholder {
      position: absolute;
      top: space(-3);
      left: 50%;
      transform: translateX(-50%);
      border-radius: space(0.25);
    }
  }

  &--dark {
    border: none;
    color: getvar($colors, "neutral", "50");

    min-height: 160px;
  }

  &--center {
    text-align: center;
  }
}

.u-primary {
  color: getvar($colors, "primary", "500");
}

.u-grey {
  color: getvar($colors, "neutral", "500");
}

.u-grey-600 {
  color: getvar($colors, "neutral", "600");
}

.u-grey-700 {
  color: getvar($colors, "neutral", "700");
}

.u-grey-800 {
  color: getvar($colors, "neutral", "800");
}

.u-grey-bg {
  background-color: getvar($colors, "neutral", "100");
}

.u-imagePlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: getvar($colors, "neutral", "50");
  padding: space(0.75);
  width: space(4.5);
  height: space(4.5);

  &__wrapper {
    border-radius: space(0.5);
    border: 1px solid getvar($colors, "neutral", "300");
    padding: space(0.5);
    display: inline-block;
    position: relative;
    width: space(3.5);
    height: space(3.5);
  }

  &__svg {
    background-color: getvar($colors, "neutral", "100");
    border-radius: space(0.5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      display: flex;
    }
  }
}

.u-line {
  border-bottom: 1px solid getvar($colors, "neutral", "300");
  padding-bottom: space();
}

.u-flex-pos-right {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.u-pill {
  border-radius: space();
  display: inline-block;
  padding: space(0.2) space(1.75);
  font-size: space(0.75);

  &--success {
    border: solid 1px getvar($colors, "success", "600");
    background-color: getvar($colors, "success", "200");
  }

  &--warning {
    border: solid 1px getvar($colors, "warning", "600");
    background-color: getvar($colors, "warning", "200");
  }

  &--error {
    border: solid 1px getvar($colors, "error", "600");
    background-color: getvar($colors, "error", "200");
  }
}

.u-skeleton {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.u-position-relative {
  position: relative;
}

.u-overlay {
  background-color: rgba(getvar($colors, "neutral", "50"), 0.8);
  position: absolute;
  width: 100%;
  height: 100%;
}

.u-avatar {
  max-width: space(6);
}

.u-list-tick {
  list-style: none;
}

.u-list-tick::before {
  content: "✓";
}

.u-rounded-button {
  border-radius: 100px;
}
