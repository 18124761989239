body,
html {
  background-color: getvar($colors, "primary", "50");
  font-size: getvar($typography, "base-font-size");
  font-family: getvar($typography, "font-family-body");
  font-weight: 400;
  line-height: getvar($typography, "base-line-height");
  letter-spacing: -0.2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  box-sizing: border-box;
  overflow-y: auto;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
ul,
ol,
p {
  margin-bottom: space();
  margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
  font-family: getvar($typography, "font-family-titles");
}

p,
.p {
  font-size: space(1);
  line-height: space(1.5);

  &-sm {
    font-size: space(1.125);

    @include md {
      font-size: space(1.375);
    }
  }

  &-noMb {
    margin-bottom: space(0.25);

    @include md {
      margin-bottom: space();
    }
  }
}

h1,
.h1 {
  font-size: space(1.5);
  line-height: space(2);
  letter-spacing: -0.3px;

  @include sm {
    font-size: space(2);
    line-height: space(2.5);
  }

  @include md {
    font-size: space(2.5);
    line-height: space(3);
  }
}

h2,
.h2 {
  font-size: space(1.5);
  line-height: space(2);

  @include md {
    font-size: space(2);
    line-height: space(2.5);
  }

  &-sm {
    font-size: space(1.375);
    @include md {
      font-size: space(1.5);
    }
  }
}

h3,
.h3 {
  font-size: space(1.125);
  line-height: space(1.5);

  @include md {
    font-size: space(1.5);
    line-height: space(2);
  }
}

h4,
.h4 {
  font-size: space(1.125);
  line-height: space(1.5);
}

ul,
ol {
  padding-left: space();
  margin-bottom: 0;

  li {
    margin-bottom: space();
  }
}

a {
  color: getvar($colors, "primary", "500");
  text-decoration: underline 0.1em rgba(getvar($colors, "primary", "600"), 0);
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    color: getvar($colors, "primary", "600");
    text-decoration-color: rgba(getvar($colors, "primary", "600"), 1);
  }
}

.body-xl {
  line-height: space(1.5);
  font-size: space(1.125);
  font-weight: 600;

  @include lg {
    font-size: space(1.5);
    line-height: space(2);
  }
}

.body-l {
  font-size: space(1);
  line-height: space(1.5);
  @include md {
    font-size: space(1.125);
    line-height: space(1.5);
  }
}

.body-m {
  font-size: space(1);
  line-height: space(1.25);
}

.body-s {
  font-size: space(0.875);
  line-height: space(1.125);
}

.body-xs {
  font-size: space(0.75);
  line-height: space(1);
}

.body-note {
  color: getvar($colors, "neutral", "400");
  font-style: italic;
}

textarea {
  resize: none;
  height: space(3);
}

p.contact-disclaimer {
  text-align: center;
  padding: space(1) space(1) 0 space(1);
  font-size: space(0.875);
  margin-top: space(0.5);

  @include lg {
    max-width: 365px;
    margin: 0 auto;
  }
}

.svg-offset {
  &--mb1 {
    svg {
      transform: translateY(space(-1));
    }
  }
}
