@import "src/themes/daikiri/styles/index.scss";
.auth-carousel {
  padding: 3rem 4rem;
}

.carousel-slide {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    color: getvar($colors, "primary", "500");
  }
  .subtitle {
    text-align: center;
  }

  img {
    margin-bottom: 64px;
    margin-top: 46px;
  }
}

.auth__container--carousel {
  background-color: getvar($colors, "primary", "50") !important;
}
